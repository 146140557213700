import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Text, Flex, Heading, Button } from "@theme-ui/components";

import { StyledModalMessage, StyledModalWindow } from "../../components/modals";
import GetErrorDescription from "../../components/GetErrorDescription";
import SubmitButton from "../../components/SubmitButton";
import { activateTravelPackService, getOsDescriptionDetails } from "./Utilities";
import Calendar from "../UpdatePackage/Calendar";
import TravelPackCard from "./TravelPackCard";

import {
  getAdditionalSrv,
  getNonSharedBeneficiaryUnits,
  getTravelPlan,
  setNonSharedBeneficiaryUnitStatus,
  setPlanError,
  setUpdateAccountProductStatus,
  updateAccountProduct,
} from "../../redux/slices/PlanSlice";
import { getISODate } from "../../common/Utilities";
import { CRUISES_CODE } from "../../common/Constants";

const noConfirmation = {
  isOpen: false,
  message: "",
  service: null,
  isError: false,
  travelPack: false,
  isSecondConfirmation: false,
};
const dateNow = () => new Date().setHours(0, 0, 0, 0);

const TravelPacks = ({ selectedCountry }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { account, eastLinkPhoneNumber, isCommercialAccount } = useSelector(state => state.user);
  const {
    travelPlan,
    travelPacksServiceList,
    travel_packs_service_list_status,
    travelPlanConfig,
    update_account_product_status,
    planError,
  } = useSelector(state => state.plan);

  const [scheduledDate, setScheduledDate] = useState(new Date(dateNow()));
  const [isCalendarError, setIsCalendarError] = useState(false);
  const [isAPIError, setIsAPIError] = useState(false);
  const displayTravelServices =
    travelPacksServiceList?.length > 0 && travelPlan?.travelPackCode !== "";

  let [confirmation, setConfirmation] = useState({
    ...noConfirmation,
  });

  const serviceFee =
    confirmation.service?.setupFee > 0
      ? confirmation.service?.setupFee
      : confirmation.service?.rcFee;

  const nextMonth = new Date();
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  useEffect(() => {
    if (update_account_product_status === "success" && confirmation.travelPack) {
      window.scrollTo(0, 0);
      dispatch(setNonSharedBeneficiaryUnitStatus(null));
      dispatch(setUpdateAccountProductStatus(null));
      const isImmediate = scheduledDate.getTime() === dateNow();

      setConfirmation({
        isOpen: true,
        message: (
          <FormattedMessage
            id={
              isImmediate
                ? "lbl.activate_travel_packs_confirmation"
                : "lbl.scheduled_travel_packs_confirmation"
            }
            values={{
              service: confirmation.service?.osDescription
                ? confirmation.service.osDescription.split("|")[0]
                : confirmation.service?.description,
              date: intl.formatDate(getISODate(scheduledDate), {
                dateStyle: "medium",
              }),
            }}
          />
        ),
        isSecondConfirmation: true,
      });
    }

    if (update_account_product_status === "failed" && confirmation.travelPack) {
      setIsAPIError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    account?.accountCode,
    dispatch,
    travel_packs_service_list_status,
    update_account_product_status,
  ]);

  const onAddService = service => {
    setConfirmation({
      isOpen: true,
      service,
      isError: false,
    });
  };

  const onCancel = () => {
    if (confirmation.isSecondConfirmation) {
      dispatch(getTravelPlan(account.accountCode));
      dispatch(getAdditionalSrv(account.accountCode));
      dispatch(getNonSharedBeneficiaryUnits(account.accountCode));
    }
    setIsAPIError(false);
    setConfirmation(noConfirmation);
    setScheduledDate(new Date(dateNow()));
    setIsCalendarError(false);
  };

  const onConfirm = date => {
    dispatch(
      updateAccountProduct({
        accountCode: account.accountCode,
        product: activateTravelPackService(
          date,
          account.productCode,
          travelPlan,
          confirmation.service.code
        ),
      })
    );
    setConfirmation({ ...confirmation, isOpen: false, travelPack: true });
  };

  const onSubmit = () => {
    if (!scheduledDate || scheduledDate.getTime() < dateNow()) {
      setIsCalendarError(true);
    } else if (scheduledDate.getTime() === dateNow()) {
      onConfirm();
    } else {
      if (travelPlan.withSpc) {
        setConfirmation({
          isOpen: true,
          message: (
            <FormattedMessage
              id="lbl.spc_active"
              values={{ eastLinkPhoneNumber, isCommercialAccount }}
            />
          ),
          isError: true,
        });
      } else {
        onConfirm(getISODate(scheduledDate));
      }
    }
  };

  const closeError = () => {
    setIsAPIError(false);
    dispatch(setPlanError(null));
    setIsCalendarError(false);
    dispatch(setUpdateAccountProductStatus(null));
    setScheduledDate(new Date(dateNow()));
    setConfirmation(noConfirmation);
  };

  const onDateChange = data => {
    setScheduledDate(data);
    setIsCalendarError(false);
  };

  const filtredServices =
    travelPacksServiceList?.filter(service =>
      selectedCountry !== ""
        ? travelPlanConfig?.travelPack.countryServiceMapping[selectedCountry]?.includes(
            service.code
          )
        : true
    ) ?? [];

  const displayTravelPackSection = () => {
    return travelPlanConfig.travelPack.countryServiceMapping[selectedCountry] !== undefined;
  };

  const TravelPackModal = () => (
    <StyledModalWindow
      isOpen={confirmation.isOpen}
      message={confirmation.message}
      onRequestClose={onCancel}>
      <>
        {confirmation.message && (
          <Button onClick={onCancel}>
            <FormattedMessage id="lbl.ok" />
          </Button>
        )}

        {!confirmation.message && (
          <Flex
            px="large"
            sx={{
              flexDirection: "column",
              textAlign: ["center", "left"],
              maxWidth: ["95%", "35.82rem", "38.82rem"],
            }}>
            <Heading>
              <FormattedMessage id="lbl.add_travel_pack" />
            </Heading>

            {confirmation.service?.osDescription ? (
              getOsDescriptionDetails(confirmation.service.osDescription)
            ) : (
              <Text
                variant="heading3"
                sx={{
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  height: "5rem",
                }}>
                {confirmation.service?.description}
              </Text>
            )}
            <Flex mt={["default", "larger"]} sx={{ justifyContent: ["center", "left"] }}>
              <Text
                variant="heading3"
                sx={{
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  alignSelf: "center",
                }}>
                {intl.formatNumber(serviceFee, {
                  style: "currency",
                  currency: "CAD",
                  currencySign: "standard",
                })}
                {confirmation.service?.setupFee === 0 && (
                  <FormattedMessage id="lbl.monthly_short" />
                )}
              </Text>
            </Flex>

            <Flex
              mt={["default", "larger"]}
              sx={{ alignItems: "center", flexDirection: ["column", "row"] }}>
              <Text mr="default" variant="subheadline" sx={{ fontWeight: "semiBold" }}>
                <FormattedMessage id="lbl.valid_from" />:
              </Text>
              <Calendar
                minDate={new Date()}
                maxDate={nextMonth}
                date={scheduledDate}
                setDate={onDateChange}
                error={isCalendarError}
              />
            </Flex>
            <SubmitButton
              mt={("small", "large")}
              text="lbl.ok"
              onSubmit={onSubmit}
              sx={{ alignSelf: "center" }}
            />
          </Flex>
        )}
      </>
    </StyledModalWindow>
  );
  if (!displayTravelPackSection())
    return (
      <Flex
        mt={travelPlan?.withSpc ? "small" : "default"}
        sx={{ width: "100%", flexDirection: "column" }}>
        <Heading variant="heading2">
          <FormattedMessage id="lbl.available_travel_plans" />
        </Heading>
        <Flex variant="layout.section">
          <Text color="textDark" ml={10}>
            <FormattedMessage
              id="lbl.travel_packs_not_available_for_country"
              values={{
                country:
                  selectedCountry === CRUISES_CODE
                    ? intl.formatMessage({ id: "lbl.cruises" })
                    : intl.formatDisplayName(selectedCountry, { type: "region" }),
              }}
            />
          </Text>
        </Flex>
      </Flex>
    );

  return (
    <Flex mt={"default"} sx={{ width: "100%", flexDirection: "column" }}>
      <Heading variant="heading2">
        <FormattedMessage id="lbl.available_travel_plans" />
      </Heading>

      {!displayTravelServices && (
        <Flex variant="layout.section">
          <Text color="textDark">
            <FormattedMessage id="lbl.no_available_countries" />
          </Text>
        </Flex>
      )}

      {displayTravelServices && (
        <Flex
          sx={{
            flexDirection: "column",
            whiteSpace: "pre",
          }}>
          {filtredServices.length === 0 && (
            <Flex variant="layout.section">
              <Text color="textDark">
                <FormattedMessage id="lbl.no_travel_packs_services_available" />
              </Text>
            </Flex>
          )}
          <Flex
            sx={{
              gap: ["1rem", "2rem", "3rem"],
              display: "grid",
              gridTemplateColumns: [
                "100%",
                "repeat(2, 21rem)",
                "repeat(2, 22rem)",
                "repeat(3, 24.25rem)",
              ],
            }}>
            {travel_packs_service_list_status === "loading" &&
              Array.from({ length: 3 }, (_, index) => (
                <Flex key={index} variant="layout.card" sx={{ padding: 0, height: "12rem" }}></Flex>
              ))}

            {filtredServices.map((service, index) => (
              <TravelPackCard key={index} service={service} onAdd={() => onAddService(service)} />
            ))}
          </Flex>
        </Flex>
      )}

      {confirmation.isOpen && <TravelPackModal />}

      <StyledModalMessage
        isOpen={isAPIError && !!planError}
        message={planError && <GetErrorDescription error={planError} />}
        onRequestClose={closeError}
        type="error">
        <Button onClick={closeError}>
          <FormattedMessage id="lbl.ok" />
        </Button>
      </StyledModalMessage>
    </Flex>
  );
};

export default TravelPacks;
