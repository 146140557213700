import React from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@theme-ui/components";

import GetIPPAYErrorDescription from "../../components/GetIPPAYErrorDescription/GetIPPAYErrorDescription";
import { StyledModalMessage } from "../../components/modals";

const PaymentResponseModal = ({
  isDisplayPaymentResponse,
  onPaymentResponseClose,
  paymentMessage,
  tryAgain,
  response,
  isError,
  isPayment,
}) => {
  return (
    <>
      <StyledModalMessage
        isOpen={isDisplayPaymentResponse && !isError}
        message={
          isPayment ? (
            <FormattedMessage
              id="lbl.your_payment_was_successful"
              values={{
                transactionNumber: response?.paymentNumber,
              }}
            />
          ) : (
            <FormattedMessage id={paymentMessage ?? "lbl.success_add_card"} />
          )
        }
        onRequestClose={onPaymentResponseClose}
        type="error">
        <Button onClick={onPaymentResponseClose}>
          <FormattedMessage id="lbl.ok" />
        </Button>
      </StyledModalMessage>

      <StyledModalMessage
        isOpen={isDisplayPaymentResponse && isError}
        message={<GetIPPAYErrorDescription errorCode={response?.errorCode} />}
        onRequestClose={tryAgain}
        type="error">
        <Button onClick={tryAgain}>
          <FormattedMessage id="lbl.Try_Again" />
        </Button>
      </StyledModalMessage>
    </>
  );
};

export default PaymentResponseModal;
