import React, { useRef } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";

import { Flex, Text, Heading } from "@theme-ui/components";

import { useOverflow } from "../../hooks/useOverflow";
import { StyledModalWindow } from "../modals";
import { SelfcareAmount } from "../base";
import Tooltip from "../Tooltip";
import { getPaymentMethod } from "../../common/Utilities";

const PaymentDetailModel = ({ isOpen, onRequestClose, payment }) => {
  const _getTransactionTypeDescription = transactionType => {
    let languageId = "";
    switch (transactionType) {
      case "P":
        languageId = "lbl.payment";
        break;
      case "R":
        languageId = "lbl.refund";
        break;
      case "RP":
        languageId = "lbl.reserved_payment";
        break;
      case "RR":
        languageId = "lbl.reserved_refund";
        break;
      default:
        languageId = "lbl.na";
    }
    return <FormattedMessage id={languageId} />;
  };

  const _getFormattedNumber = value => {
    let formattedValue;
    if (value == null) {
      formattedValue = <FormattedMessage id="lbl.not_available" />;
    } else {
      formattedValue = <SelfcareAmount amount={value} />;
    }

    return formattedValue;
  };

  const parseCreditCard = number => {
    const parsed =
      number.substring(0, 4) +
      " " +
      number.substring(4, 8) +
      " " +
      number.substring(8, 12) +
      " " +
      number.substring(12, 16);

    return parsed;
  };

  const Row = ({ labelId, value, ...props }) => {
    const valueRef = useRef();
    const [isOverflow] = useOverflow(valueRef);

    return (
      <Flex sx={{ flexDirection: ["column", "row"] }}>
        <Text color="disabled" mt={["small", "medium"]} sx={{ width: ["100%", "11rem"] }}>
          <FormattedMessage id={labelId} />
        </Text>

        {!isOverflow && (
          <Flex
            ref={valueRef}
            mt={["small", "medium"]}
            ml="small"
            sx={{ width: ["100%", "13.81rem"] }}>
            <Text
              color="primary"
              sx={{
                width: ["100%", "13.81rem"],
                whiteSpace: ["initial", "initial", "nowrap"],
                ...props.sx,
              }}
              {...props}>
              {value}
            </Text>
          </Flex>
        )}
        {isOverflow && (
          <Flex mt={["small", "medium"]} ml="small" sx={{ width: ["100%", "13.81rem"] }}>
            <Tooltip position="left" value={value}>
              <Text
                color="primary"
                sx={{
                  overflow: "hidden",
                  display: "inline-block",
                  textOverflow: "ellipsis",
                  whiteSpace: ["initial", "initial", "nowrap"],
                  width: ["100%", "13.81rem"],
                }}>
                {value}
              </Text>
            </Tooltip>
          </Flex>
        )}
      </Flex>
    );
  };

  return (
    <StyledModalWindow isOpen={isOpen} onRequestClose={onRequestClose}>
      <Flex
        px={["small", "large"]}
        sx={{
          height: "100%",
          bg: "modalBg",
          flexDirection: "column",
          textAlign: "left",
        }}>
        <Heading>
          <FormattedMessage id="lbl.payment_details_heading" />
        </Heading>

        <Flex sx={{ flexDirection: ["column", "column", "row"] }}>
          <Flex
            bg="contentBg"
            p={["default", "larger"]}
            sx={{
              minWidth: payment.creditCard ? ["100%", "30rem"] : "18.688rem",
              border: "solid 1px #c2c2c2",
              borderRadius: "card",
              flexDirection: "column",
            }}>
            <Text variant="subheadline" mb={["default", 0]}>
              <FormattedMessage id="lbl.payment.transaction_details" />
            </Text>
            <Row labelId="lbl.payment.transaction_id" value={payment.transactionNumber} />
            <Row
              labelId="lbl.payment.transaction_type"
              value={_getTransactionTypeDescription(payment.transactionType)}
            />
            <Row labelId="lbl.payment.amount" value={_getFormattedNumber(payment.value)} />
            <Row
              labelId="lbl.payment.payment_date"
              value={<FormattedDate value={payment.transactionDate} dateStyle="medium" />}
            />
            <Row labelId="lbl.payment.payment_method" value={getPaymentMethod(payment)} />
            <Row labelId="lbl.payment.remark" value={payment.remark} />
          </Flex>
          {payment.creditCard && (
            <Flex
              bg="contentBg"
              p={["default", "larger"]}
              ml={[0, 0, "large"]}
              mt={["default", "default", 0]}
              sx={{
                width: ["100%", "30rem"],
                border: "solid 1px #c2c2c2",
                borderRadius: "card",
                flexDirection: "column",
              }}>
              <Text variant="subheadline" mb={["default", 0]}>
                <FormattedMessage id="lbl.cc.credit_card_information" />
              </Text>

              <Row
                labelId="lbl.cc.card_number"
                value={parseCreditCard(payment.creditCard.number)}
              />
              <Row labelId="lbl.cc.cardholdername" value={payment.creditCard.holderName} />
              <Row labelId="lbl.cc.card_type" value={payment.creditCard.descr} />
              <Row
                labelId="lbl.cc.expiration_date"
                value={`${payment.creditCard.expMonth}/${payment.creditCard.expYear}`}
              />
              <Row labelId="lbl.cc.reference_number" value={payment.referenceId} />
              <Row labelId="lbl.cc.authorization_number_short" value={payment.authCode} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </StyledModalWindow>
  );
};

export default PaymentDetailModel;
