import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import _ from "lodash";

import { Flex, Grid } from "@theme-ui/components";

import ServiceFeaturesCard from "../ServiceAgreementPackage/ServiceFeaturesCard";
import DeviceDetailsContainer from "../DeviceDetailsContainer";
import TravelPlanCard from "../TravelPlanCard/TravelPlanCard";
import SubscriberInformation from "../SubscriberInformation";
import { MESSAGES_CODES, UDF_CATEGORIES } from "../../common/Constants";
import UsageDetailsCard from "../UsageDetailsCard";
import ImageContainer from "../ImageContainer";
import CBOCard from "../CBOCard/CBOCard";
import FUBGroup from "../FUBGroup";

const ServiceAgreementPostpaid = () => {
  const intl = useIntl();
  const { account, cboTTicket, master } = useSelector(state => state.user);
  const {
    nonSharedBeneficiaryUnits,
    non_shared_beneficiary_unit_status,
    sharedBucketsDetails,
    shared_buckets_details_status,
  } = useSelector(state => state.plan);

  const [banner, setBanner] = useState();

  const hide = cboTTicket?.status === "FINISHED" && !cboTTicket?.resolutionTypeCode;
  const isResidential = master?.udfCategory !== UDF_CATEGORIES.COMMERCIAL;
  const isAccountTerminate = account?.accountStatus === MESSAGES_CODES.TERMINATE;

  const isAccountActiveOrSuspended =
    account && account.accountStatus && ["ACTIVE", "SUSPENDED"].includes(account.accountStatus);

  useEffect(() => {
    let shuffled = _.shuffle(window._env_.commercials.homeRight);
    setBanner(_.find(shuffled, e => e.lang === intl.locale));
  }, [intl.locale]);

  const nonSharedPlans = nonSharedBeneficiaryUnits?.beneficiaryUnits.filter(
    fub => fub.totalQuantity !== 0
  );
  const sharedPlans = sharedBucketsDetails?.bucketsDetails.filter(fub => fub.totalQuantity !== 0);

  return (
    <Flex mb="huge" sx={{ flexDirection: "column", gap: "medium" }}>
      <Flex
        sx={{ flexDirection: ["column", "row"], gap: ["1rem", "2rem", "3rem"], flexWrap: "wrap" }}>
        <Flex sx={{ flexDirection: "column" }}>
          <Grid
            sx={{
              gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr", "1fr 1fr 1fr"],
              gridTemplateRows: "9.75rem",
              columnGap: ["1rem", "2rem", "3rem"],
              rowGap: ["1rem", 0],
            }}>
            <SubscriberInformation sx={{ gridRowEnd: "span 2" }} />
            {isAccountActiveOrSuspended ? (
              <ServiceFeaturesCard sx={{ gridRowEnd: "span 2" }} />
            ) : (
              <Flex
                sx={{
                  display: ["none", "none", "none", "flex"],
                  gridRowEnd: ["auto", "auto", "auto", "span 2"],
                }}
              />
            )}

            {cboTTicket?.step && isAccountActiveOrSuspended && !hide && isResidential ? (
              <CBOCard
                cboTTicket={cboTTicket}
                sx={{
                  gridRowEnd: ["auto", "auto", "auto", "span 2"],
                }}
              />
            ) : (
              <Flex
                sx={{
                  display: ["none", "none", "none", "flex"],
                  gridRowEnd: ["auto", "auto", "auto", "span 2"],
                }}
              />
            )}

            {account?.accountStatus === "ACTIVE" && <TravelPlanCard />}
            {!isAccountTerminate && (
              <>
                <DeviceDetailsContainer />
                <UsageDetailsCard />
              </>
            )}
          </Grid>
        </Flex>
        {isResidential && banner && (
          <ImageContainer
            mt={["0", "0", "4.25rem", "4.25rem"]}
            image={banner}
            sx={{
              maxWidth: ["100%", "100%", "26rem", "26rem"],
              height: "fit-content",
              display: ["none", "initial"],
            }}
          />
        )}
      </Flex>

      {isResidential && banner && (
        <ImageContainer
          image={banner}
          sx={{
            maxWidth: ["100%", "100%", "26rem", "26rem"],
            display: ["initial", "none"],
            alignSelf: "center",
          }}
        />
      )}

      {account && ["ACTIVE", "SUSPENDED"].includes(account.accountStatus) && (
        <>
          <FUBGroup
            isLoading={
              !non_shared_beneficiary_unit_status ||
              non_shared_beneficiary_unit_status === "loading" ||
              !account
            }
            title={intl.formatMessage({ id: "lbl.your_plans_heading" })}
            fubs={nonSharedBeneficiaryUnits && nonSharedPlans}
            showMembers={false}
          />
          <FUBGroup
            isLoading={
              !shared_buckets_details_status ||
              shared_buckets_details_status === "loading" ||
              !account
            }
            title={intl.formatMessage({ id: "lbl.shared_plan_heading" })}
            fubs={sharedBucketsDetails && sharedPlans}
            showMembers={false}
          />
        </>
      )}
    </Flex>
  );
};

export default ServiceAgreementPostpaid;
