import React, { useRef } from "react";
import { Flex, Box, Text, Link } from "@theme-ui/components";
import { useIntl } from "react-intl";
import { useOverflow } from "../../hooks/useOverflow";
import Tooltip from "../Tooltip";

const SummaryRow = ({ tooltip, value, fieldWidth = "7rem", children, ...props }) => {
  const intl = useIntl();
  const valueRef = useRef();
  const [isOverflow] = useOverflow(valueRef);

  const showValue = value && value !== "" && !children;
  const showValuePlaceHolder = !showValue && tooltip && tooltip !== "" && !children;

  const valueTextComponent = SummaryRow.buildValueTextComponent({ value: value, ref: valueRef });

  return (
    <Box
      mb={["default", "medium", "small", "largeish"]}
      {...props}
      sx={{
        ":hover .myTooltip": {
          display: isOverflow && "block",
        },
      }}>
      <Flex
        sx={{
          alignItems: "baseline",
          textAlign: "left",
          flexDirection: ["column", "column", "column", "row"],
          gap: "small",
        }}>
        {tooltip && (
          <Text
            className="myPlaceholder"
            color="disabled"
            sx={{
              whiteSpace: "pre",
              width: ["100%", "100%", "100%", fieldWidth],
              flexShrink: 0,
            }}>
            {tooltip}:
          </Text>
        )}

        {children ? (
          <Text>{children}</Text>
        ) : isOverflow ? (
          <Tooltip value={value} sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {valueTextComponent}
          </Tooltip>
        ) : (
          valueTextComponent
        )}

        {showValuePlaceHolder && (
          <Text
            color="note"
            sx={{
              whiteSpace: ["initial", "nowrap"],
              overflow: "hidden",
              textAlign: "left",
            }}>
            {intl.formatMessage({ id: "lbl.placeholder_info_not_specified" })}
          </Text>
        )}
      </Flex>
    </Box>
  );
};

SummaryRow.buildValueTextComponent = ({ value, ref } = {}) => {
  return (
    <Text
      ref={ref}
      className="myValue"
      sx={{
        whiteSpace: ["initial", "initial", "initial", "nowrap"],
        overflow: "hidden",
        textOverflow: "ellipsis",
        flexGrow: 0,
      }}>
      {value}
    </Text>
  );
};

SummaryRow.buildValueLinkComponent = ({ linkDetails, ref, sx } = {}) => {
  const { href, label } = linkDetails;
  return (
    <Link
      ref={ref}
      href={href}
      target="_blank"
      variant="nav"
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        flexGrow: 0,
        color: "blue",
        ...sx,
      }}>
      {label}
    </Link>
  );
};

export default SummaryRow;
