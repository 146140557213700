import React from "react";
import { Box } from "@theme-ui/components";

const Lock = ({ fill = "none", stroke = "#0c3865", ...props }) => (
  <Box
    as="svg"
    width="12"
    height="13.976"
    viewBox="4.026 -0.065 14.635 14.712"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g data-name="Group 181" transform="matrix(1, 0, 0, 1, 5, 0)">
      <path
        d="M249 76.25v-1.945a3.8 3.8 0 1 1 7.61 0v1.945"
        transform="translate(-246.805 -70.4)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <g data-name="Rectangle 65" transform="translate(0 5.976)" fill={fill} stroke={stroke}>
        <rect width="12" height="8" rx="2" stroke={stroke} />
        <rect x=".5" y=".5" width="11" height="7" rx="1.5" fill="none" />
      </g>
      <path
        data-name="Path 106"
        d="M9 1v2.537"
        transform="translate(-3 7.744)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
      />
    </g>
  </Box>
);
export default Lock;
