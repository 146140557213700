import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Flex, Heading, Text, Link } from "@theme-ui/components";
import { CRUISES_CODE } from "../../common/Constants";

const PayPerUse = ({ selectedCountry }) => {
  const intl = useIntl();

  const { travelPlanConfig } = useSelector(state => state.plan);

  const displayPayPerUseSection = () => {
    return travelPlanConfig.payPerUse[selectedCountry] !== undefined;
  };

  if (!displayPayPerUseSection()) {
    return (
      <Flex mt="small" sx={{ width: "100%", flexDirection: "column" }}>
        <Heading variant="heading2">
          <FormattedMessage id="lbl.pay_per_use" />
        </Heading>
        <Flex variant="layout.section">
          <Text color="textDark">
            <FormattedMessage
              id="lbl.pay_per_use_not_available_for_country"
              values={{
                country:
                  selectedCountry === CRUISES_CODE
                    ? intl.formatMessage({ id: "lbl.cruises" })
                    : intl.formatDisplayName(selectedCountry, { type: "region" }),
              }}
            />
          </Text>
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex mt="small" sx={{ width: "100%", flexDirection: "column" }}>
      <Heading variant="heading2">
        <FormattedMessage id="lbl.pay_per_use" />
      </Heading>

      {/* <Text mt="default" variant="description">
        <FormattedMessage
          id="lbl.easyTravel_message"
          values={{
            p: chunks => <Text as="p">{chunks}</Text>,
            a: chunks => (
              <Link
                variant="support"
                href={intl.formatMessage({ id: easyTravelLink })}
                target="_blank">
                {chunks}
              </Link>
            ),
          }}
        />
      </Text> */}
      <Flex variant="layout.section">
        <Text color="textDark">
          <FormattedMessage
            id="lbl.pay_per_use_message"
            values={{
              country:
                selectedCountry === CRUISES_CODE
                  ? intl.formatMessage({ id: "lbl.cruises" })
                  : intl.formatDisplayName(selectedCountry, { type: "region" }),
              tariffMinutes: travelPlanConfig.payPerUse[selectedCountry].tariffMinutes,
              tariffMessages: travelPlanConfig.payPerUse[selectedCountry].tariffMessages,
              tariffMB: travelPlanConfig.payPerUse[selectedCountry].tariffMB,
              b: value => <b>{value}</b>,
              a: chunks => (
                <Link variant="nav" href={"https://" + chunks} target="_blank">
                  {chunks}
                </Link>
              ),
            }}
          />
        </Text>
      </Flex>
    </Flex>
  );
};

export default PayPerUse;
