import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Storage from "store2";

import { Flex } from "@theme-ui/components";

import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import { LANGUAGE_ISOCODE } from "../../common/Localization";
import { ENGLISH, MESSAGES_CODES, UDF_CATEGORIES } from "../../common/Constants";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import {
  getAdditionalSrv,
  getNonSharedBeneficiaryUnits,
  getSharedBucketsDetails,
  getTravelPacksServiceList,
  getTravelPlanConfig,
  getTravelPlan,
  loadAlwaysDisplayOverageBdus,
  loadZeroUsageHiddenBdusList,
} from "../../redux/slices/PlanSlice";
import {
  getAccountList,
  getNearestBillableAccount,
  setNbaAccountStatus,
  getCboTicket,
  getContacts,
  getDeviceDetails,
  setPrevAccountCode,
  getAccount,
  setAccountDetails,
} from "../../redux/slices/UserSlice";
import { BrandingContext } from "../../contexts/BrandingContext";
import { MobileNavMenu } from "../../components/NavMenu/MobileNavMenu";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intlParams = useContext(SelfcareIntlContext);
  const { accountCode: accountCodePath } = useParams();
  const { config } = useContext(BrandingContext);

  const { accountCode: rootAccountCode } = useSelector(state => state.auth);
  const {
    master,
    prevAccountCode,
    account,
    nba_account_status,
    serviceAgreements,
    account_list_status,
    totalNumAccounts,
    userError,
  } = useSelector(state => state.user);

  const [initialLoad, setInitialLoad] = useState(false);
  const [isAccountLoaded, setIsAccountLoaded] = useState(!!account);
  const isAccountTerminate = account?.accountStatus === MESSAGES_CODES.TERMINATE;
  const isCommercialAccount = master?.udfCategory === UDF_CATEGORIES.COMMERCIAL;

  useEffect(() => {
    if (!master && nba_account_status !== "loading" && !userError) {
      dispatch(getNearestBillableAccount(rootAccountCode));
    }
  }, [master, nba_account_status, dispatch, rootAccountCode, userError]);

  // fetch data for current account, skips if the account didn't change
  useEffect(() => {
    if (account) {
      const code = accountCodePath ? accountCodePath : account.accountCode;
      if (code !== prevAccountCode && !isAccountTerminate) {
        dispatch(setPrevAccountCode(code));
        dispatch(getDeviceDetails(code));
        dispatch(getAdditionalSrv(code));
        dispatch(getTravelPlan(code));
        dispatch(getCboTicket(code));
        dispatch(getTravelPacksServiceList(code));
        dispatch(getTravelPlanConfig(code));
        dispatch(getContacts(code));
        if (["ACTIVE", "SUSPENDED"].includes(account?.accountStatus)) {
          dispatch(loadZeroUsageHiddenBdusList());
          dispatch(loadAlwaysDisplayOverageBdus());
          dispatch(getNonSharedBeneficiaryUnits(code));
          dispatch(getSharedBucketsDetails(code));
        }
      }
    }
  }, [account, accountCodePath, dispatch, isAccountTerminate, prevAccountCode]);

  useEffect(() => {
    // because API returns null account if logged as MA
    if (master && accountCodePath && !isAccountLoaded) {
      dispatch(getAccount(accountCodePath));
      setIsAccountLoaded(true);
    } else if (
      master &&
      !account &&
      serviceAgreements &&
      serviceAgreements.length > 0 &&
      !isAccountLoaded
    ) {
      dispatch(getAccount(serviceAgreements[0].accountCode));
      setIsAccountLoaded(true);
    }
  }, [
    account,
    accountCodePath,
    account_list_status,
    dispatch,
    initialLoad,
    isAccountLoaded,
    master,
    serviceAgreements,
  ]);

  useEffect(() => {
    if (nba_account_status === "failed") {
      history.push("/error");
    }

    if (nba_account_status === "success") {
      const isMaster = !master.serviceAgreement;
      const masterLanguage = isCommercialAccount ? ENGLISH : master.accountLanguage;
      const acctLang = isMaster ? masterLanguage : account.accountLanguage;
      const language = Storage.get("language", LANGUAGE_ISOCODE[acctLang]);
      dispatch(
        setAccountDetails({
          phoneNumber: isCommercialAccount
            ? config.commercialPhoneNumber
            : config.residentialPhoneNumber,
          isCommercialAccount,
        })
      );
      language && intlParams.switchLanguageTo(language);

      dispatch(setNbaAccountStatus(null));
    }
  }, [
    account,
    config,
    dispatch,
    history,
    intlParams,
    isCommercialAccount,
    master,
    nba_account_status,
  ]);

  useEffect(() => {
    const SA_INITIAL_CHUNK_SIZE = 50;
    if (master && !master.serviceAgreement && !serviceAgreements && !account_list_status) {
      dispatch(getAccountList({ accountCode: rootAccountCode, chunkSize: SA_INITIAL_CHUNK_SIZE }));
      setInitialLoad(true);
    }

    // the user has navigated to a new page (reset states) and account list didnt finish loading
    if (account_list_status === "loading" && !account) {
      setInitialLoad(true);
    }

    if (account_list_status === "success" && initialLoad) {
      setInitialLoad(false);
      if (totalNumAccounts && totalNumAccounts > SA_INITIAL_CHUNK_SIZE) {
        dispatch(getAccountList({ accountCode: rootAccountCode, chunkSize: totalNumAccounts }));
      }
    }
  }, [
    account,
    account_list_status,
    dispatch,
    initialLoad,
    master,
    rootAccountCode,
    serviceAgreements,
    totalNumAccounts,
  ]);

  return (
    <Flex
      bg="contentBg"
      sx={{
        flexDirection: "column",
        minHeight: "100vh",
        overflowX: "hidden",
      }}>
      <Flex sx={{ display: ["none", "inline", "inline"] }}>
        <Header />
      </Flex>
      <Flex sx={{ display: ["inline", "none", "none"] }}>
        <MobileNavMenu />
      </Flex>
      <Flex
        sx={{
          mt: ["3rem", "4rem", "7rem"],
          width: "100%",
          maxWidth: "headerContentMaxWidth",
          flexDirection: "column",
        }}
        marginLeft="auto"
        marginRight="auto"
        px={["default", "large", "huge", "mobileModalExtraOffset"]}>
        <Flex sx={{ flexDirection: "column" }}>{children}</Flex>
      </Flex>
      <Footer />
    </Flex>
  );
};

export default Layout;
