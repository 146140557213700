import React from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { Flex, Text } from "@theme-ui/components";

import SubmitButton from "../../components/SubmitButton";
import { getOsDescriptionDetails } from "./Utilities";

const TravelPackCard = ({ service, onAdd }) => {
  const intl = useIntl();
  const serviceFee = service.setupFee > 0 ? service.setupFee : service.rcFee;
  return (
    <Flex variant="layout.card" sx={{ height: "inherit", justifyContent: "space-between" }}>
      {service.osDescription ? (
        getOsDescriptionDetails(service.osDescription)
      ) : (
        <Flex>
          <Text
            variant="heading3"
            sx={{
              whiteSpace: "normal",
              overflowWrap: "break-word",
              height: "5rem",
            }}>
            {service.description}
          </Text>
        </Flex>
      )}
      <Flex sx={{ flexDirection: "column" }}>
        <Flex
          sx={{
            mt: "small",
            justifyContent: "space-between",
            alignItems: "flex-end",
            height: "3rem",
            width: "100%",
          }}>
          <Text
            variant="heading3"
            sx={{
              whiteSpace: "normal",
              overflowWrap: "break-word",
              height: "5rem",
              alignSelf: "center",
              mt: "3rem",
            }}>
            {intl.formatNumber(serviceFee, {
              style: "currency",
              currency: "CAD",
              currencySign: "standard",
            })}
            {service.setupFee === 0 && <FormattedMessage id="lbl.monthly_short" />}
          </Text>

          <SubmitButton text="lbl.add" data-testid="TravelPack" onSubmit={onAdd} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TravelPackCard;
