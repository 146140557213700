import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Flex, Text, Progress } from "@theme-ui/components";

import { SelfcareIcon } from "../../components/icons";
import { getDaysUntil, isMoney, unitDescriptionTranslation } from "../../common/Utilities";
import { SelfcareAmount } from "../../components/base";

const computeUnits = (
  baseUnitRate,
  unitDescription,
  totalQuantity,
  usedQuantity,
  usedByOwnerAccount
) => {
  let result = {
    total: null,
    used: null,
    unit: null,
    usedByOwner: null,
    carriedOverQuantity: null,
  };

  result.total = totalQuantity === -1 ? totalQuantity : totalQuantity / baseUnitRate;
  result.used = usedQuantity / baseUnitRate;
  result.usedByOwner = usedByOwnerAccount / baseUnitRate;

  result.unit = unitDescription;

  return result;
};

const formatQuantity = quantity => {
  return parseFloat(quantity.toFixed(2));
};
const formatPercentage = quantity => {
  return Number.parseInt(quantity.toFixed(1).replace(/[.,]0$/, ""));
};

const getFontSize = description => {
  const textLength = description.length;
  const maxLength = 34;
  let fontSize = 6;
  if (textLength >= maxLength) {
    fontSize = 4;
  }
  return [4, 4, fontSize];
};

const buildValidUntilDateDetails = (master, account, fub) => {
  const now = new Date().getTime();
  let billingPeriodToDate = master
    ? new Date(master.billingPeriod?.toDate)
    : account
    ? new Date(account.billingPeriod?.toDate)
    : new Date();
  let serviceExpirationDate = fub.serviceExpirationDate
    ? new Date(fub.serviceExpirationDate)
    : new Date("2050-12-31");
  if (serviceExpirationDate.getFullYear() === 2050) {
    return {
      expires: false,
      expired: false,
      serviceExpiration: false,
      validUntilDate: billingPeriodToDate,
      validUntilNoOfDays: getDaysUntil(billingPeriodToDate.getTime()) + 1,
    };
  } else {
    return {
      expires: true,
      expired: serviceExpirationDate.getTime() < now,
      serviceExpiration: true,
      validUntilDate: serviceExpirationDate,
      validUntilNoOfDays: getDaysUntil(serviceExpirationDate.getTime()),
    };
  }
};

const TravelPackFUBCard = ({ fub, ...props }) => {
  const intl = useIntl();
  const { master, account } = useSelector(state => state.user);

  const fubUnits = computeUnits(
    fub.baseUnitRate,
    fub.unitDescription,
    fub.totalQuantity,
    fub.usedQuantity,
    fub.usedByOwnerAccount
  );

  // hide overage buckets with no usage
  if (fubUnits.used === 0 && fub.overage) {
    return null;
  }

  const validUntilDateDetails = buildValidUntilDateDetails(master, account, fub);

  // if the FUB is expired, hide the card
  if (validUntilDateDetails.expired) return null;

  const getPlanTitle = () => {
    if (fub.serviceInvoiceDescription || fub.serviceOsDescription) {
      if (fub.overage) {
        return fub.serviceOsDescription
          ? fub.serviceOsDescription.split("|")[0] +
              " - " +
              intl.formatMessage({ id: "lbl.overage" })
          : fub.serviceInvoiceDescription + " - " + intl.formatMessage({ id: "lbl.overage" });
      }
      return fub.serviceOsDescription
        ? fub.serviceOsDescription.split("|")[0]
        : fub.serviceInvoiceDescription;
    }
    return fub.description;
  };
  const planTitle = getPlanTitle();
  const planTitlefontSize = getFontSize(planTitle);

  const isDataOrMoney = (consumedUnit = fub.unitDescription) => {
    const unitDescriptions = ["MB", "GB", "$"];
    return unitDescriptions.includes(consumedUnit);
  };

  const parseAmount = consumedAmount =>
    isDataOrMoney() ? Number(consumedAmount).toFixed(2) : consumedAmount;

  // shows used units and the unit description
  const Consumed = ({ consumedAmount, consumedUnit = fub.unitDescription, ...props }) => {
    const unitDescription =
      !isDataOrMoney() && consumedAmount === 1
        ? consumedUnit.slice(0, consumedUnit.length - 1)
        : consumedUnit;
    if (isMoney(fubUnits.unit))
      return (
        <Text mx="tiny" sx={{ fontWeight: "semiBold", ...props.sx }}>
          <SelfcareAmount amount={consumedAmount} />
        </Text>
      );
    return (
      <Text mx="tiny" {...props} sx={{ fontWeight: "semiBold", ...props.sx }}>
        <FormattedMessage
          id="lbl.consumed"
          values={{ amount: consumedAmount, unit: unitDescriptionTranslation(unitDescription) }}
        />
      </Text>
    );
  };

  // shows the description of the plan
  const PlanDescription = () => (
    <Flex
      my="small"
      sx={{
        height: "3.75rem",
        justifyContent: "center",
        textAlign: "center",
        alignItems: "center",
      }}>
      <Text variant="headline" sx={{ lineHeight: 1, fontSize: planTitlefontSize }}>
        {planTitle}
      </Text>
    </Flex>
  );

  // shows the prograss bar with the infinity icon or percentage (based on total quantity)
  const ProgressBar = () => {
    if (fubUnits.total > 0) {
      return (
        <Flex mb="tiny" sx={{ alignItems: "center", justifyContent: "center" }}>
          <Flex
            p="tiny"
            sx={{
              backgroundColor: "progressBg",
              height: "1.375rem",
              width: "80%",
              borderRadius: 10,
              alignItems: "center",
            }}>
            <Progress max={1} value={fubUnits.used / fubUnits.total} />
          </Flex>

          <Text ml="tiny" sx={{ width: "2.9rem", textAlign: "right" }}>
            {formatPercentage((fubUnits.used / fubUnits.total) * 100)}%
          </Text>
        </Flex>
      );
    }
    return (
      <Flex
        mb="tiny"
        p="tiny"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "progressBg",
          width: "100%",
          borderRadius: 10,
          height: "1.375rem",
        }}>
        <SelfcareIcon name="infinity" />
      </Flex>
    );
  };

  // shows "used" or "out of" message (based on total quantity)
  const TotalUsed = () => {
    if (fubUnits.total > 0) {
      return (
        <Text mb="small" sx={{ textAlign: "center" }}>
          <Consumed consumedAmount={parseAmount(formatQuantity(fubUnits.used))} />
          <FormattedMessage id="lbl.out_of" />
          <Consumed consumedAmount={formatQuantity(fubUnits.total)} />
        </Text>
      );
    }
    return (
      <Text mb="small" sx={{ textAlign: "center" }}>
        <Consumed consumedAmount={parseAmount(formatQuantity(fubUnits.used))} />
        {intl.formatMessage({ id: "lbl.used" }).toLocaleLowerCase()}
      </Text>
    );
  };

  // shows "expiration date — days" message
  const ValidUntil = () => {
    return (
      <Flex pb="small" sx={{ flexDirection: "column", alignItems: "center" }}>
        <Text sx={{ fontWeight: "medium" }}>
          {validUntilDateDetails.serviceExpiration && (
            <FormattedMessage id="lbl.cc.expiration_date" />
          )}
          {!validUntilDateDetails.serviceExpiration && <FormattedMessage id="lbl.cycle_ends" />}
        </Text>

        <Text>
          {intl.formatDate(validUntilDateDetails.validUntilDate, {
            year: "numeric",
            month: "short",
            day: "2-digit",
          })}
        </Text>
      </Flex>
    );
  };

  return (
    <Flex
      variant="layout.card"
      {...props}
      sx={{ padding: 0, px: "large", py: "small", ...props.sx }}>
      <PlanDescription />
      <ProgressBar />
      <Flex sx={{ flexDirection: "column", justifyContent: "space-around", flexGrow: 1 }}>
        <TotalUsed />
        <ValidUntil />
      </Flex>
    </Flex>
  );
};

TravelPackFUBCard.displayName = "TravelPackFUBCard";
export default TravelPackFUBCard;
