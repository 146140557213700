import React, { forwardRef, useState, useEffect, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Flex, Text, Progress } from "@theme-ui/components";

import { SelfcareIntlContext } from "../../contexts/SelfcareIntlContext";
import { LANGUAGE_ISOCODE } from "../../common/Localization";
import { SelfcareAmount } from "../../components/base";

const EasyTravelCard = forwardRef(({ easyTravelDetails, ...props }, ref) => {
  const intl = useIntl();
  const { locale } = useContext(SelfcareIntlContext);

  const [isDateValid, setIsDateValid] = useState(easyTravelDetails.remainingDuration > 0);
  const [percentage, setPercentage] = useState(0);
  const [displayedTime, setDisplayedTime] = useState(0);
  const isFrench = locale == LANGUAGE_ISOCODE.French;

  useEffect(() => {
    let remainingDurationInMs = easyTravelDetails.remainingDuration * 1000;
    if (remainingDurationInMs < 0) setIsDateValid(false);
    const formattedTime = intl.formatTime(remainingDurationInMs, {
      hourCycle: "h23",
    });
    const differenceInMinutes = remainingDurationInMs / (1000 * 60);

    setDisplayedTime(formattedTime);
    setPercentage(differenceInMinutes / (24 * 60));
  }, [easyTravelDetails, intl]);

  const Description = () => (
    <Flex
      sx={{
        mt: [0, 0, "default"],
        mb: isFrench && !isDateValid ? ["tiny", "default", "default", 0] : "small",
        height: isFrench && !isDateValid ? ["3.75rem", "fit-content"] : "3.75rem",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Text variant="headline" sx={{ lineHeight: 1 }}>
        {easyTravelDetails.destination}
      </Text>
    </Flex>
  );

  // shows the prograss bar
  const ProgressBar = () => {
    return (
      <Flex mb="tiny" sx={{ alignItems: "center", justifyContent: "center" }}>
        <Flex
          p="tiny"
          sx={{
            backgroundColor: "progressBg",
            height: "1.375rem",
            width: "80%",
            borderRadius: 10,
            alignItems: "center",
          }}>
          <Progress max={1} value={percentage} />
        </Flex>
      </Flex>
    );
  };

  const ActiveTime = () => {
    return (
      <Flex
        sx={{
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          px: "default",
        }}>
        {isDateValid && (
          <Text mb="medium" sx={{ fontWeight: "medium" }}>
            {intl.formatMessage({ id: "lbl.hours_expired" }, { time: displayedTime })}
          </Text>
        )}
        {!isDateValid && (
          <Text
            mb="small"
            sx={{
              fontWeight: "medium",
              fontSize: [1, 1, 1, 2],
              textAlign: "justify",
              px: "tiny",
            }}>
            {intl.formatMessage({ id: "lbl.expired_easyTravel_message" })}
          </Text>
        )}
      </Flex>
    );
  };

  const Fee = () => {
    return (
      <Flex
        sx={{
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}>
        <Text sx={{ fontWeight: "medium", fontSize: [2, 2, 2, 3] }}>
          {intl.formatMessage(
            { id: "lbl.total_days_used" },
            {
              noDays: easyTravelDetails.numberOfPeriods,
            }
          )}
        </Text>
        <Flex>
          <Text sx={{ fontWeight: "medium", fontSize: [2, 2, 2, 3] }}>
            <FormattedMessage id="lbl.total_charges" />
          </Text>

          <Text ml="tiny" sx={{ fontWeight: "medium", fontSize: [2, 2, 2, 3] }}>
            <SelfcareAmount amount={easyTravelDetails.hsrFee * easyTravelDetails.numberOfPeriods} />
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      ref={ref}
      variant="layout.card"
      {...props}
      sx={{
        padding: "0 !important",
        ...props.sx,
      }}>
      <Flex sx={{ flexDirection: "column", height: "12.25rem", justifyContent: "center" }}>
        <Description />
        {isDateValid && <ProgressBar />}
        <ActiveTime />
      </Flex>
      <Fee />
    </Flex>
  );
});

EasyTravelCard.displayName = "EasyTravelCard";

export default EasyTravelCard;
